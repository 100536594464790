import { HttpParams } from '@angular/common/http';
import { ApiForm } from '~lib/helpers/api-form';
import { ServiceLocator } from '~lib/helpers/service-locator';
import { IJsonMap } from '~lib/types';
import { AuthenticationService } from '~shared/services/authentication.service';
import { UriRoute } from '~shared/services/laravel-api.service';

export interface EmailLoginFormData extends IJsonMap {
  email: string;
  password: string;
  userId:string;
  restaurantName:string;
}

/**
 * Formulario para iniciar sesión con correo y contraseña.
 */
export class EmailLoginForm extends ApiForm<EmailLoginFormData> {
  constructor(initialData: Partial<EmailLoginFormData> = {}, queryParams?: HttpParams) {
    super(
      {
        method: 'POST',
        uri: new UriRoute('auth/tokens'),
        queryParams,
      },
      initialData,
      ['email', 'password','userId']
    );
  }

  /**
   * Envía el formulario, obteniendo sólo si fue exitoso o no (true|false)
   */
  async submit(): Promise<boolean>;
  async submit(getOriginalResponse: true): Promise<never>;

  async submit() {
    if (this.submiting) {
      throw new Error('Alredy submiting');
    }

    let success = false;
    this.submiting = true;

    // this.disable();

    try {
      const auth = ServiceLocator.injector.get(AuthenticationService);

      await auth.signInWithEmail(this.getRawValue());
      success = true;
    } catch (err) {
      // this.enable();
      this.handleValidationError(err);
    } finally {
      // this.enable();
      this.submiting = false;
    }

    return success;
  }
}
