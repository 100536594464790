import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Order } from '~/database/models/order';
import { IOrderData } from '~/database/models/order-data.interface';
import { ILaravelApiSingleResult, LaravelApiService, UriRoute } from '~shared/services/laravel-api.service';
import { SessionService } from '~shared/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class OrderResolver implements Resolve<Order | null | undefined> {
  constructor(private api: LaravelApiService, private sessionService: SessionService, private router: Router) {
    //
  }

  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Order | null | undefined> {
    const params = {
      order: route.paramMap.get('order') || '',
    };

    if (!params.order) {
      return null;
    }

    try {
     /* const restaurantId = this.sessionService.restaurant?.id;

      if (!restaurantId) {
        throw new Error('Restaurant is not set');
      }*/

      const result = await this.api
        .get(new UriRoute('orders/{order}', params))
        .pipe(
          first(),
          map((response) => {
            const { data } = response as ILaravelApiSingleResult<IOrderData>;

            if (!data) {
              return null;
            }
           
            const parentPath = `restaurants/${data.teamId}`;
          
            return new Order(data, data.id, parentPath);
          })
        )
        .toPromise();

      return result;
    } catch (err) {
      console.error(`Unable to get order '${params.order}'`, err);

      return null;
    }
  }
}
