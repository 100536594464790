import { ProductStatus } from '~/database/models/order-product-data.interface';
import { ApiForm } from '~lib/helpers/api-form';
import { IJsonMap } from '~lib/types';
import { UriRoute } from '~shared/services/laravel-api.service';

export interface OrderProductMetaFormData extends IJsonMap {
  meta: {
    status: ProductStatus;
    sortIndex: number;
  };
}

/**
 * Formulario actualizar el order item.
 */
export class UpdateOrderProductMetaForm extends ApiForm<OrderProductMetaFormData> {
  constructor(orderProductId: string, initialData: Partial<OrderProductMetaFormData> = {}) {
    super(
      {
        method: 'PATCH',
        uri: new UriRoute('order-products/{id}', { id: orderProductId }),
      },
      initialData,
      ['meta']
    );
  }
}
