import { QueryFn } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Relationships } from '~lib/database/firestore/relationships';

import { Order } from './order';
import { IOrderData } from './order-data.interface';
import { Table } from './table';
import { TableSession } from './table-session';
import { ITableSessionData } from './table-session-data.interface';
import { User } from './user';

export class TableSessionRelationships extends Relationships<TableSession, ITableSessionData> {
  private _table$: Observable<Table | null> | undefined;
  private _orders$: Observable<Order[]> | undefined;
  private _attendant$: Observable<User | null> | undefined;

  /**
   * Table.
   */
  public get table$() {
    if (!this._table$) {
      this._table$ = this.belongsTo(Table, 'tableId');
    }

    return this._table$;
  }

  /**
   * Attendant.
   */
  public get attendant$() {
    if (!this._attendant$) {
      this._attendant$ = this.belongsTo(User, 'attendantId', '');
    }

    return this._attendant$;
  }

  get orders$() {
    if (!this._orders$) {
      this._orders$ = this.orders((ref) => {
        return ref.orderBy('updatedAt', 'desc');
      });
    }

    return this._orders$;
  }

  orders(filters?: QueryFn<IOrderData>) {
    const orders$ = this.model
      .odm()
      .child<IOrderData>('orders', filters)
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map(({ payload }) => {
            return Order.fromPayloadDocument(payload.doc);
          });
        })
      );

    return orders$;
  }

  ordersStateChanges$(filters?: QueryFn<IOrderData>) {
    return this.model.odm().child<IOrderData>('orders', filters).stateChanges();
  }
}
