import * as moment from 'moment';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

export interface IDeliveryChargeData extends IFirebaseData {
  createdAt: string | null;
  deletedAt: string | null;
  id: string | null;
  city:string;
  deliveryCharges:{} | null;
  enabled:boolean | false;
  updatedAt: string | null;
}

/**
 * Representa una factura de pago en la base de datos.
 */
export class DeliveryCharge extends Model<IDeliveryChargeData> {
  public static type = 'delivery_charges';

  constructor(data: Partial<IDeliveryChargeData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IDeliveryChargeData = {
        createdAt: null,
        deletedAt: null,
        id: null,
        deliveryCharges:null,
        city:'',
        enabled:false,
        updatedAt: null,
    };
  // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);
    super(safeData,  data.id );
  }

  get data() {
    return this.rawData;
  }

}
