import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '~/database/models/order';
import { OrdersService } from '~shared/services/orders.service';
import { SessionService } from '~shared/services/session.service';

/**
 * Servicio para inicializar y mantener un observable de los pedidos del cliente actual.
 */
@Injectable({
  providedIn: 'root',
})
export class CurrentOrdersService {
  #activeOrders$: Observable<Order[]> | undefined;

  public get activeOrders$(): Observable<Order[]> | undefined {
    return this.#activeOrders$;
  }

  constructor(private orders: OrdersService, private restaurantService: SessionService) {}

  initializeActiveOrders(options = { force: false }): Observable<Order[]> {
    if (!this.#activeOrders$ || options.force === true) {
      const tableId = this.restaurantService.table?.id ?? undefined;

      if (!tableId) {
        throw new Error('CurrentRestaurantService had not initialized the current table');
      }

      this.#activeOrders$ = this.orders.getOrders();
      console.log(this.#activeOrders$);
    }

    return this.#activeOrders$;
  }
}
