import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinct, map } from 'rxjs/operators';
import { NotificationDocument } from '~/database/models/notification';
import { INotificationData } from '~/database/models/notification-data.interface';
import { AuthenticationService } from '~shared/services/authentication.service';
import { SessionService } from '~shared/services/session.service';

/**
 * Calcula los observables para obtener notificaciones auqnue cambie el usuario o restaurant.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  userSubscription: Subscription | undefined;
  restaurantSubscription: Subscription | undefined;

  /**
   */
  protected readonly callNotifications$$Subject: BehaviorSubject<
    Observable<NotificationDocument<{ attendantId: string | null; customerId: string; tableId: string }>[]> | undefined
  >;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get callNotifications$$(): Observable<
    Observable<NotificationDocument<{ attendantId: string | null; customerId: string; tableId: string }>[]> | undefined
  > {
    return this.callNotifications$$Subject.asObservable();
  }

  constructor(private sessionService: SessionService, private auth: AuthenticationService) {
    this.callNotifications$$Subject = new BehaviorSubject<
      | Observable<NotificationDocument<{ attendantId: string | null; customerId: string; tableId: string }>[]>
      | undefined
    >(undefined);

    this.restaurantSubscription = this.sessionService.restaurant$
      .pipe(
        distinct((restaurant) => {
          return restaurant?.id ?? null;
        })
      )
      .subscribe((restaurant) => {
        if (this.userSubscription && !this.userSubscription.closed) {
          this.userSubscription.unsubscribe();
        }

        if (!restaurant) {
          this.callNotifications$$Subject.next(undefined);

          return;
        }

        this.userSubscription = this.auth.currentUser$
          .pipe(
            distinct((user) => {
              return user?.uid ?? null;
            })
          )
          .subscribe((user) => {
            if (!user) {
              this.callNotifications$$Subject.next(undefined);

              return;
            }

            // FIXME: whereIn parece no funcionar con NULL, así que sólo se trae los del usuario actual
            const restaurantNotifications$ = restaurant
              .odm()
              .child<INotificationData<{ attendantId: string | null; customerId: string; tableId: string }>>(
                'notifications',
                (ref) => {
                  return ref
                    .where('context', '==', 'attendants')
                    .where('group', '==', 'attendant-calls')
                    .where('data.attendantId', 'in', [null, user.uid])
                    .orderBy('createdAt', 'desc')
                    .limit(20);
                }
              )
              .snapshotChanges()
              .pipe(
                map((notifications) => {
                  return notifications.map(({ payload }) => {
                    const data = payload.doc.data();
                    const parentPath = payload.doc.ref.parent.parent?.path as string;

                    return new NotificationDocument(data, payload.doc.id, parentPath);
                  });
                })
              );

            this.callNotifications$$Subject.next(restaurantNotifications$);

            return;
            // Opcion 2: Como subcolección en tables. No mantiene el stream abierto para recibir nuevas notificaciones.
            // const callNotifications$ = restaurant
            //   .odm()
            //   .child<ITableData>('tables', (ref) => {
            //     return ref.where('userId', '==', user.uid);
            //   })
            //   .snapshotChanges()
            //   .pipe(
            //     mergeMap((items) => {
            //       const tables = items.map(({ payload }) => {
            //         const data = payload.doc.data();
            //
            //         const parentPath = payload.doc.ref.parent.parent?.path as string;
            //
            //         return new Table(data, payload.doc.id, parentPath);
            //       });
            //
            //       // console.log(tables);
            //
            //       const notificationsObservables = tables.map((table) => {
            //         return table
            //           .odm()
            //           .child<INotificationData<{ attendantId: string | null; customerId: string; tableId: string }>>(
            //             'notifications',
            //             (ref) => {
            //               return (
            //                 ref
            //                   .where('context', '==', 'attendants')
            //                   // .where('data.attendantId', 'in', [null, user.uid])
            //                   .orderBy('createdAt', 'desc')
            //                   .limit(20)
            //               );
            //             }
            //           )
            //           .snapshotChanges()
            //           .pipe(
            //             map((notifications) => {
            //               return notifications.map(({ payload }) => {
            //                 const data = payload.doc.data();
            //                 const parentPath = payload.doc.ref.parent.parent?.path as string;
            //
            //                 console.log(payload.doc.ref.path);
            //
            //                 return new NotificationDocument(data, payload.doc.id, parentPath);
            //               });
            //             })
            //           );
            //       });
            //
            //       return concat(...notificationsObservables).pipe(concatAll(), toArray());
            //     })
            //   );
            //
            // this.callNotifications$$Subject.next(callNotifications$);

            // this._callNotifications$ = callNotifications$;

            // ==================
            // Opción 3: No obtiene nada. No se puede usar * como comodín para la mesa
            // console.log(user, restaurant, this.callNotifications$);

            // const callNotifications$ = restaurant
            //   .odm()
            //   .child<INotificationData<{ attendantId: string | null; customerId: string; tableId: string }>>(
            //     'tables/*/notifications',
            //     (ref) => {
            //       console.log(ref.path)
            //       return (
            //         ref
            //           .where('context', '==', 'attendants')
            //           .where('data.attendantId', 'in', [null, user.uid])
            //           .orderBy('createdAt', 'desc')
            //           .limit(20)
            //       );
            //     }
            //   )
            //   .snapshotChanges()
            //   .pipe(
            //     map((items) => {
            //       return items.map(({ payload }) => {
            //         const data = payload.doc.data();
            //         const parentPath = payload.doc.ref.parent.parent?.path as string;
            //
            //         return new NotificationDocument(data, payload.doc.id, parentPath);
            //       });
            //     })
            //   );
            //
            // this._callNotifications$ = callNotifications$;
          });
      });
  }

  protected cleanSubscriptions() {
    if (this.userSubscription && !this.userSubscription.closed) {
      this.userSubscription.unsubscribe();
    }

    if (this.restaurantSubscription && !this.restaurantSubscription.closed) {
      this.restaurantSubscription.unsubscribe();
    }
  }
}
