import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '~/database/models/order';
import { PaymentsService } from '~shared/services/payments.service';
import { SessionService } from '~shared/services/session.service';
import { User } from '~/database/models/user';
import { AuthenticationService } from '~shared/services/authentication.service';
import { ReleasePayment } from '~/database/models/release-payment';
/**
 * Servicio para inicializar y mantener un observable de los pedidos del cliente actual.
 */
@Injectable({
  providedIn: 'root',
})
export class AllPaymentsService {
  #activePayments$: Observable<ReleasePayment[]> | undefined;

  public get activePayments$(): Observable<ReleasePayment[]> | undefined {
    return this.#activePayments$;
  }
  public currentUser: User | null | undefined;
  constructor(private payments: PaymentsService,private auth: AuthenticationService,private restaurantService: SessionService) {

    const userSub = this.auth.currentUserInstance$.subscribe((user) => {
     
      this.currentUser = user;
     
    });
   
  }
 
  initializeActivePayments(options = { force: false },filters:{memberId:string}): Observable<ReleasePayment[]> {
    this.#activePayments$ = this.payments.getCustomersPayments(filters);
    return this.#activePayments$;
  }

}
