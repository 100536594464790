import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '~/database/models/order';
import { OrdersService } from '~shared/services/orders.service';
import { SessionService } from '~shared/services/session.service';
import { User } from '~/database/models/user';
import { AuthenticationService } from '~shared/services/authentication.service';
/**
 * Servicio para inicializar y mantener un observable de los pedidos del cliente actual.
 */
@Injectable({
  providedIn: 'root',
})
export class CurrentOrdersService {
  #activeOrders$: Observable<Order[]> | undefined;

  public get activeOrders$(): Observable<Order[]> | undefined {
    return this.#activeOrders$;
  }
  public currentUser: User | null | undefined;
  constructor(private orders: OrdersService,   private auth: AuthenticationService,private restaurantService: SessionService) {

    const userSub = this.auth.currentUserInstance$.subscribe((user) => {
     
      this.currentUser = user;
     
    });

  }

  initializeActiveOrders(options = { force: false }): Observable<Order[]> {
    
    if (!this.#activeOrders$ || options.force === true) {
      if(this.currentUser?.data.regType == 'regcustomer'){
      /*  const tableId = this.restaurantService.table?.id ?? undefined;

        if (!tableId) {
          throw new Error('CurrentRestaurantService had not initialized the current table');
        }*/
        console.log('reg')
        this.#activeOrders$ = this.orders.getCustomersOrders();
    
      }else{
        const tableId = this.restaurantService.table?.id ?? undefined;

        if (!tableId) {
          throw new Error('CurrentRestaurantService had not initialized the current table');
        }

        this.#activeOrders$ = this.orders.getOrders();
    
      }
      
    }

    return this.#activeOrders$;
  }
  initializeActiveDeliveryOrdersForDriver(options = { force: false }): Observable<Order[]> {
    
    // if (!this.#activeOrders$) {
      
    //     this.#activeOrders$ = this.orders.getDeliveryOrders();
    //      console.log(this.#activeOrders$);
    // }
    this.#activeOrders$ = this.orders.getDeliveryOrdersforDriver();
    return this.#activeOrders$;
  }

  initializeActiveDeliveryOrders(options = { force: false }): Observable<Order[]> {
    
    // if (!this.#activeOrders$) {
      
    //     this.#activeOrders$ = this.orders.getDeliveryOrders();
    //      console.log(this.#activeOrders$);
    // }
    this.#activeOrders$ = this.orders.getDeliveryOrders();
    return this.#activeOrders$;
  }
  initializeActiveTableOrders(options = { force: false }): Observable<Order[]> {
    
    
    // if (!this.#activeOrders$ || options.force === true) {
    //   const tableId = this.restaurantService.table?.id ?? undefined;
    //   console.log(tableId)
    //   if (!tableId) {
    //     throw new Error('CurrentRestaurantService had not initialized the current table');
    //   }

    //   this.#activeOrders$ = this.orders.getTableOrders();
    //   console.log(this.#activeOrders$);
    // }
    this.#activeOrders$ = this.orders.getTableOrders();
    return this.#activeOrders$;
  }
}
