import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Category } from '~/database/models/category';
import { ICategoryData } from '~/database/models/category-data.interface';
import { ILaravelApiSingleResult, LaravelApiService, UriRoute } from '~shared/services/laravel-api.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryResolver implements Resolve<Category | null | undefined> {
  constructor(private api: LaravelApiService, private router: Router) {
    //
  }

  public async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<Category | null | undefined> {
    // if (route.data.category instanceof Category) {
    //   return route.data.category as Category;
    // }

    const params = {
      category: route.paramMap.get('category') || '',
    };

    if (!params.category) {
      return null;
    }

    try {
      const result = await this.api
        .get(new UriRoute('categories/{category}', params))
        .pipe(
          first(),
          map((response) => {
            const { data } = response as ILaravelApiSingleResult<ICategoryData>;

            if (!data) {
              return null;
            }

            return new Category(data, data.id);
          }),
        )
        .toPromise();

      return result;
    } catch (err) {
      console.error(`Unable to get category '${params.category}'`, err);

      return null;
    }
  }
}
