import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOrderStatusData, OrderStatus } from '~/database/models/order-status';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusesService {
  public readonly list$: Observable<IOrderStatusData[]>;

  constructor() {
    const os = new OrderStatus();

    this.list$ = os
      .odm()
      .collection((ref) => {
        return ref.orderBy('value');
      })
      .valueChanges();
  }
}
