import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/firestore';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeliveryCharge, IDeliveryChargeData } from '~/database/models/delivery-charge';
import { Restaurant } from '~/database/models/restaurant';
import { AuthenticationService } from './authentication.service';
import { LaravelApiService } from './laravel-api.service';
import { SessionService } from './session.service';

export interface IDeliveryChargeFilters {
  city?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class DeliveryChargesService {
  public  list$: Observable<DeliveryCharge[]> | undefined;
  latitude: any | null | undefined;
  longitude: any  | null |  undefined;
  rest_latitude: any | null | undefined;
  rest_city : string | '' | undefined;
  rest_longitude: any  | null |  undefined;
  delivery_charges : {} | null | undefined;
  constructor(
    protected readonly api: LaravelApiService,
    protected readonly auth: AuthenticationService,
    protected readonly currentRestaurant: SessionService
  ) {}
  
  getDeliveryCharges(filters: IDeliveryChargeFilters = {}): Observable<DeliveryCharge[]> {
    filters = _.omitBy(filters, _.isUndefined) as IDeliveryChargeFilters;
     
    const currentUser = this.auth.currentUser?.uid;
    if(!currentUser){
      throw new Error('Undefined Customer');
    }
    
    const result = new DeliveryCharge()
      .odm()
      .collection((ref) => {   
        let q: Query<IDeliveryChargeData> | undefined;

        _.each(filters, (value, filter) => {
         
          if (!q) {
            // FIXME: ¿Posible bug? ¿No debería asignar a q?
            q =  ref.where(filter, '==', value);
          } else {
            q = q.where(filter, '==', value);
          }
        });

        if (q) {
          q = q.orderBy('updatedAt', 'desc');
        } else {
          q = ref.orderBy('updatedAt', 'desc');
        }
      
        return q;
      })
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((o) => {
            // TODO: Finetune payload.type
            const payments = new DeliveryCharge(o.payload.doc.data(), o.payload.doc.id, o.payload.doc.ref.parent.parent?.path);
             
            return payments;
          });
        })
      );
    return result;
  }
  
 
}
