import IFirebaseData from '~lib/database/firebase-data.interface';

export enum ProductStatus {
  Exhausted = -1,
  None = 0,
  Preparing = 1,
  Ready = 2,
}

export interface IOrderProductMeta extends IFirebaseData {
  /**
   * Representa el estado de preparación del producto.
   */
  status: ProductStatus;
  sortIndex: number;
}

export interface IOrderProductData extends IFirebaseData {
  id: string | null;
  orderId: string;
  productId: string;
  price: string | number;
  quantity: number;
  notes: string;
  offerApplicable: boolean;
  promoquantity: number;
  promocode: string;
  meta: IOrderProductMeta;
  teamId:string;
  createdAt: string | null;
  updatedAt: string | null;
}
