import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/firestore';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReleasePayment } from '~/database/models/release-payment';
import { IReleasePaymentData } from '~/database/models/release-payment';
import { AuthenticationService } from './authentication.service';
import { LaravelApiService } from './laravel-api.service';
import { SessionService } from './session.service';

export interface IPaymentFilters {
  memberId?: string | null;
  teamId?: string;
  attendantId?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  #orders: Observable<ReleasePayment[]> | undefined;
  public  list$: Observable<ReleasePayment[]> | undefined;
  constructor(
    protected readonly api: LaravelApiService,
    protected readonly auth: AuthenticationService,
    protected readonly currentRestaurant: SessionService
  ) {}
  
  getCustomersPayments(filters: IPaymentFilters = {}): Observable<ReleasePayment[]> {
    filters = _.omitBy(filters, _.isUndefined) as IPaymentFilters;
     
    const currentUser = this.auth.currentUser?.uid;
    if(!currentUser){
      throw new Error('Undefined Customer');
    }
    
    const result = new ReleasePayment()
      .odm()
      .collection((ref) => {   
        let q: Query<IReleasePaymentData> | undefined;

        _.each(filters, (value, filter) => {
         
          if (!q) {
            // FIXME: ¿Posible bug? ¿No debería asignar a q?
            q =  ref.where(filter, '==', value);
          } else {
            q = q.where(filter, '==', value);
          }
        });

        if (q) {
          q = q.orderBy('updatedAt', 'desc');
        } else {
          q = ref.orderBy('updatedAt', 'desc');
        }
      
        return q;
      })
      .snapshotChanges()
      .pipe(
        map((action) => {
          return action.map((o) => {
            // TODO: Finetune payload.type
            const payments = new ReleasePayment(o.payload.doc.data(), o.payload.doc.id, o.payload.doc.ref.parent.parent?.path);
             
            return payments;
          });
        })
      );
    return result;
  }
 
}
