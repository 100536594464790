import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';


export interface IBookingData extends IFirebaseData {
  id: string | null;
  tableLocation: string;
  name: string;
  email: string;
  surname: string;
  comment: string;
  bookingDateTime:string;
  teamId:string;
  phone:string;
  comments:string;
  numberOfPersons: string;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}

/**
 * Representa un plan que agrupa módulos.
 */
export class Booking extends Model<IBookingData> {
  public static type = 'bookings';

  constructor(data: Partial<IBookingData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IBookingData = {
        id: '',
        tableLocation: '',
        name: '',
        email: '',
        surname: '',
        comment: '',
        bookingDateTime:'',
        teamId:'',
        phone:'',
        comments:'',
        numberOfPersons: '',
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

 
}
