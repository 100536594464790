import { ApiForm } from '~lib/helpers/api-form';
import { IJsonMap } from '~lib/types';
import { UriRoute } from '~shared/services/laravel-api.service';

export interface OrderStatusFormData extends IJsonMap {
  orderStatusId: string;
}

/**
 * Formulario actualizar el order item.
 */
export class UpdateOrderStatusForm extends ApiForm<OrderStatusFormData> {
  constructor(orderId: string, initialData: Partial<OrderStatusFormData> = {}) {
    super(
      {
        method: 'PATCH',
        uri: new UriRoute('orders/{id}', { id: orderId }),
      },
      initialData,
      ['orderStatusId']
    );
  }
}
