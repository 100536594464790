import * as moment from 'moment';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { JsonArray } from '~lib/types';

export interface IReleasePaymentData extends IFirebaseData {
  createdAt: string | null;
  deletedAt: string | null;
  id: string | null;
  orderIds:string;
  pdfName: string;
  payment_method: string;
  payment_referance: string;
  payment_type: string;
  user_id: string;
  teamId: string;
  userId: string;
  memberId: string;
  attendantId: string;
  updatedAt: string | null;
 
}

/**
 * Representa una factura de pago en la base de datos.
 */
export class ReleasePayment extends Model<IReleasePaymentData> {
  public static type = 'release_payments';

  constructor(data: Partial<IReleasePaymentData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IReleasePaymentData = {
        createdAt: null,
        deletedAt: null,
        id: null,
        orderIds:'',
        pdfName: '',
        payment_method: '',
        payment_referance: '',
        payment_type: '',
        user_id: '',
        teamId: '',
        userId: '',
        memberId: '',
        attendantId: '',
        updatedAt: null,
     
    };
  // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);
    super(safeData,  data.id );
  }

  get data() {
    
    return this.rawData;
  }

}
