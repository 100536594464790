import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cart } from '~/database/models/cart';
import { CartService } from '~shared/services/cart.service';
import { SessionService } from '~shared/services/session.service';
import { User } from '~/database/models/user';
import { AuthenticationService } from '~shared/services/authentication.service';
/**
 * Servicio para inicializar y mantener un observable de los pedidos del cliente actual.
 */
@Injectable({
  providedIn: 'root',
})
export class CurrentCartService {
  #activeCarts$: Observable<Cart[]> | undefined;

  public get activeCarts$(): Observable<Cart[]> | undefined {
    return this.#activeCarts$;
  }
  public currentUser: User | null | undefined;
  constructor(private orders: CartService,   private auth: AuthenticationService,private restaurantService: SessionService) {

    const userSub = this.auth.currentUserInstance$.subscribe((user) => {
     
      this.currentUser = user;
     
    });

  }

  initializeActiveCarts(options = { force: false }): Observable<Cart[]> {
    
    this.#activeCarts$ = this.orders.getCustomersCarts();
    return this.#activeCarts$;

    
  }


}
