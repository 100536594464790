import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { IOrderStatusData } from '~/database/models/order-status';
import { OrderStatusesService } from '~shared/services/order-statuses.service';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusesResolver implements Resolve<IOrderStatusData[]> {
  constructor(private statusesService: OrderStatusesService, private router: Router) {
    //
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IOrderStatusData[]> {
    return this.statusesService.list$.pipe(first());
  }
}
