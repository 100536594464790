import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Cart } from '~/database/models/cart';
import { ICartData } from '~/database/models/cart-data.interface';
import { ILaravelApiSingleResult, LaravelApiService, UriRoute } from '~shared/services/laravel-api.service';
import { SessionService } from '~shared/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class CartResolver implements Resolve<Cart | null | undefined> {
  constructor(private api: LaravelApiService, private sessionService: SessionService, private router: Router) {
    //
  }

  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Cart | null | undefined> {
    const params = {
      cart: route.paramMap.get('cart') || '',
    };

    if (!params.cart) {
      return null;
    }

    try {
      // const restaurantId = this.sessionService.restaurant?.id;

      // if (!restaurantId) {
      //   throw new Error('Restaurant is not set');
      // }

      const result = await this.api
        .get(new UriRoute('carts/{cart}', params))
        .pipe(
          first(),
          map((response) => {
            const { data } = response as ILaravelApiSingleResult<ICartData>;

            if (!data) {
              return null;
            }
            const parentPath = `restaurants/${data.teamId}`;

            return new Cart(data, data.id, parentPath);
          })
        )
        .toPromise();

      return result;
    } catch (err) {
      console.error(`Unable to get cart '${params.cart}'`, err);

      return null;
    }
  }
}
